body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* ToDo NAVBAR*/
.navbar{
  font-weight: bolder;
  font-family: sans-serif;
  background-color: #020202;
  color:yellow;

}
.navbar-light .navbar-brand{
  color:white;
}

.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 0;

}
.navbar-nav a{
  font-weight: bolder;
  font-family: sans-serif;

  margin: 10px 10px;
  font-size: large;
  color:white;

}

.navbar-nav a:link { text-decoration: none;}


.navbar-nav a:visited { text-decoration: none;}


.navbar-nav a:hover { text-decoration: none;}


.navbar-nav a:active {
  text-decoration:none;
  color: #473BF0;
}
.navbar-nav a:current{
  text-decoration: none;
}


.navbar-nav{
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

}

.navbar-nav li {
  display: inline-block;
  margin: 10px 30px

}
#TopRowInfo .row{
  height: 250px;
}



/*CSS FOR HOMEPAGE*/
#HomePage{
  background-color: black;
  color: white;
}
#HomePage h1{
  color: white;
  font-weight: bolder;
  font-family: sans-serif;
  text-transform: uppercase;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;

}
/*.glow {*/
/*  color: #fff;*/
/*  animation: glow 3s infinite;*/

/*}*/

/*@keyframes glow {*/
/*  from {*/
/*    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #473BF0, 0 0 40px #473BF0, 0 0 50px #473BF0, 0 0 60px #473BF0, 0 0 70px #e60073;*/
/*  }*/

/*  to {*/
/*    text-shadow: 0 0 20px #fff, 0 0 30px #473BF0, 0 0 40px #473BF0, 0 0 50px #473BF0, 0 0 60px #473BF0, 0 0 70px #473BF0, 0 0 80px #473BF0;*/
/*  }*/
/*}*/
span{
  color: #473BF0;
}

a:link { text-decoration: none;}


a:visited { text-decoration: none;}

a:hover { text-decoration: none;}


a:active {
  text-decoration:none;
}
#Home{
  font-size: 150%;
  font-family: sans-serif;
}
#LandingPageH1 h1{
  font-size: 200%;

  color: white;

}
#About{
  font-family: sans-serif;

}
#con-Logo{
  height: 25px;
  width: 25px;
}
#BorderOnTOp{
  margin-left: 15px;
  border-top: 5px solid #473BF0;
  width: 13%;

}
/* The actual timeline (the vertical ruler) */
.main-timeline {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #473BF0;

  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -13px;
  background-color: #473BF0;
  border: 5px solid white;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  padding: 0 40px 20px 0;
  left: 0;
}

/* Place the container to the right */
.right {
  padding: 0 0 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -12px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .main-timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0px;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 18px;
  }

  .left::before {
    right: auto;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0;
  }
}
#PortfolioCards{
  border: 3px solid #473BF0;
}
*{
  background-color: black;
}